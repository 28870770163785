<template lang="">
  <modal-padrao
    ref="modal-movimentar-proximo-anterior-setor"
    max-width="80%"
    :titulo="tituloModal"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.fechar')"
    :mostra-botao-salvar="mostraBotaoSalvar"
    @ok="movimentaSetor"
  >
    <div class="d-flex">
      <div v-if="instrumentoNome">
        <v-chip
          class="mr-1"
          small
        >
          {{ $t('modulos.operacao.formulario.instrumento') }}:
          {{ `${instrumentoNome}` }}
        </v-chip>
      </div>
      <div v-if="tipoInstrumentoNome">
        <v-chip
          class="mr-1"
          small
        >
          {{ $t('modulos.operacao.formulario.tipo_instrumento') }}:
          {{ `${tipoInstrumentoNome}` }}
        </v-chip>
      </div>
      <div v-if="ordemServicoNome">
        <v-chip
          class="mr-1"
          small
        >
          {{ $t('modulos.operacao.formulario.ordem_servico') }}:
          {{ `${ordemServicoNome}` }}
        </v-chip>
      </div>
    </div>
    <v-form
      ref="form"
      class="row mt-2 mb-2"
    >
      <input-text
        v-model="form.setorAtualNome"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.setor_atual')"
        :placeholder="''"
        disabled
      />
      <input-text
        v-if="movimentacao == 'Proximo'"
        :key="keyAtualizaProximoSetorNome"
        v-model="form.proximoSetorNome"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.proximo_setor')"
        :placeholder="''"
        disabled
      />
      <input-text
        v-if="movimentacao == 'Anterior'"
        :key="keyAtualizaSetorAnteriorNome"
        v-model="form.setorAnteriorNome"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.setor_anterior')"
        :placeholder="''"
        disabled
      />
      <input-select
        v-if="movimentacao == 'Outro'"
        :key="keyAtualizaOutroSetorNome"
        v-model="form.outroSetor"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.proximo_setor')"
        :placeholder="''"
        :options="sequenciaSetores"
      />
      <!-- <input-text
        v-model="form.situacaoAtualNome"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.situacao_atual')"
        :placeholder="''"
        disabled
      />
      <input-text
        v-model="form.novaSituacaoNome"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.nova_situacao')"
        :placeholder="''"
        disabled
      /> -->
    </v-form>
  </modal-padrao>
</template>
<script>
import OperacaoService from '@common/services/cadastros/OperacaoService';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
export default {
  components: {},
  props: {
    instrumentoNome: { type: String, default: '' },
    tipoInstrumentoNome: { type: String, default: '' },
    ordemServicoNome: { type: String, default: '' },
  },
  data() {
    return {
      form: {},
      sequenciaSetores: [],
      movimentacao: null,
      mostraBotaoSalvar: true,
      keyAtualizaProximoSetorNome: 1,
      keyAtualizaSetorAnteriorNome: 20,
      keyAtualizaOutroSetorNome: 50,
    };
  },
  computed: {
    tituloModal: function () {
      if (this.movimentacao == 'Proximo')
        return this.$t('modulos.operacao.botoes.movimentar_proximo_setor');
      else if (this.movimentacao == 'Anterior')
        return this.$t('modulos.operacao.botoes.movimentar_setor_anterior');
      else return this.$t('modulos.operacao.botoes.movimentar_outro_setor');
    },
  },
  watch: {
    'form.outroSetor'(valor) {
      if (valor) {
        this.form.proximoSetorId = valor;
      }
    },
  },
  methods: {
    listar(ordemServicoId, instrumentoId, somenteOS = false) {
      let params = {
        ordemServicoId: ordemServicoId,
      };
      if (instrumentoId) params.instrumentoId = instrumentoId;

      OperacaoService.buscaMovimentacoesSetor(params)
        .then((res) => {
          this.form.ordemServicoId = res.data.ordemServico?.id;
          if(!somenteOS) this.form.instrumentoId = res.data.instrumento?.id;
          this.form.proximaSituacao = res.data.novaSituacao;

          this.form.proximoSetorNome = res.data.proximoSetor?.nome;
          this.form.setorAnteriorNome = res.data.setorAnterior?.nome;

          if (res.data.proximoSetor?.id && this.movimentacao == 'Proximo') {
            this.form.proximoSetorId = res.data.proximoSetor?.id;
          }
          if (res.data.setorAnterior?.id && this.movimentacao == 'Anterior') {
            this.form.proximoSetorId = res.data.setorAnterior?.id;
          }

          if (
            this.movimentacao == 'Outro' &&
            res.data.sequenciaSetores.length
          ) {
            this.sequenciaSetores = new DropdownModel(
              res.data.sequenciaSetores
            );

            this.sequenciaSetores = this.sequenciaSetores.filter(
              (item) => item.value !== res.data.setorAtual?.id
            );

            if (res.data.proximoSetor?.id) {
              this.form.outroSetor = res.data.proximoSetor?.id;
              this.form.proximoSetorId = res.data.proximoSetor?.id;
            }
          }

          this.form.setorAtualNome = res.data.setorAtual?.nome;
          this.form.situacaoAtualNome = this.$t(
            `modulos.operacao.formulario.${res.data.situacaoAtual}`
          );
          this.form.novaSituacaoNome = this.$t(
            `modulos.operacao.formulario.${res.data.novaSituacao}`
          );

          if (!res.data.proximoSetor?.id && this.movimentacao == 'Proximo') {
            this.mostraBotaoSalvar = false;
            this.toastAlerta(
              this.$t(`modulos.operacao.erros.nao_encontrado_proximo_setor`)
            );
          }
          if (!res.data.setorAnterior?.id && this.movimentacao == 'Anterior') {
            this.mostraBotaoSalvar = false;
            this.toastAlerta(
              this.$t(`modulos.operacao.erros.nao_encontrado_setor_anterior`)
            );
          }

          this.keyAtualizaProximoSetorNome++;
          this.keyAtualizaSetorAnteriorNome++;
          this.keyAtualizaOutroSetorNome++;
        })
        .catch((err) => {
          this.mostraBotaoSalvar = false;
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {});
    },
    reiniciaDados() {
      this.form = {};
      this.mostraBotaoSalvar = true;
    },
    async abrirModal(ordemServicoId, instrumentoId, movimentacao, somenteOS = false) {
      await this.reiniciaDados();
      this.movimentacao = movimentacao;
      this.listar(ordemServicoId, instrumentoId, somenteOS);
      this.$refs['modal-movimentar-proximo-anterior-setor'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-movimentar-proximo-anterior-setor'].fecharModal();
    },
    movimentaSetor() {
      if (!this.form.proximoSetorId) {
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.selecione_proximo_setor`)
        );

        return;
      }
      this.form.operacaoMovimentoSetor = this.movimentacao;
      OperacaoService.movimentarSetor(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.operacao.movimentacao_sucesso`));

          this.$emit('atualizaListagem');
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
