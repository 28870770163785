<template>
  <div class="row">
    <input-text
      v-model="filtro.codigoInstrumento"
      class="col-6"
      :label="$t('modulos.operacao.filtro.codigo_instrumento')"
      :placeholder="$t('modulos.operacao.filtro.codigo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeTipoInstrumento"
      class="col-6"
      :label="$t('modulos.operacao.filtro.tipo_instrumento')"
      :placeholder="$t('modulos.operacao.filtro.tipo_instrumento')"
      em-filtro
    />
    <input-select
      v-model="filtro.situacaoOrdemServico"
      class="col-6"
      :label="$t('modulos.operacao.filtro.situacao')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.situacao"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.codigoOrdemServico"
      class="col-6"
      :label="$t('modulos.operacao.filtro.ordem_servico')"
      :placeholder="$t('modulos.operacao.filtro.ordem_servico')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeClienteParticipante"
      class="col-12"
      :label="$t('modulos.operacao.filtro.nome_cliente')"
      :placeholder="$t('modulos.operacao.filtro.nome_cliente')"
      em-filtro
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        situacao: helpers.SituacaoOrdemServicoEnum,
      },
    };
  },
  mounted(){
    this.opcoes.situacao = this.opcoes.situacao.sort((a, b) => a.text.localeCompare(b.text));
  }
};
</script>
